import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationItem } from '../shared/navigation.model';
import { AdobeEventsService } from '../shared/adobe-events.service';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent implements OnInit {
  header: string;
  subHeader: string;
  bodyContent: string;
  footerContent: string;

  constructor(private adobeEvents: AdobeEventsService) {}

  @Input() notification: NotificationItem;
  @Output() deleteEvent = new EventEmitter<string>();

  ngOnInit() {
    if (this.notification) {
      const formattedContent = this.formatNotificationDetail(
        this.notification.notificationSummary,
        this.notification.notificationDetail
      );
      this.header = formattedContent.header;
      this.subHeader = formattedContent.subHeader;
      this.bodyContent = formattedContent.body;
      this.footerContent = formattedContent.footer;

    }
  }

  formatNotificationDetail(
    summary: string,
    detail: string
  ): { header: string; subHeader: string | null; body: string | null; footer: string | null } {
    const header = `<p>${summary.trim()}</p>`;

    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(detail, 'text/html');
    const paragraphs = Array.from(parsedDocument.body.children);

    let subHeader: string | null = null;
    let bodyContent: string | null = null;
    let footerContent: string | null = null;

    for (const paragraph of paragraphs) {
      const paragraphText = paragraph.textContent?.trim() || '';

      if (this.isFooterLine(paragraphText)) {
        footerContent = paragraph.outerHTML;
      } else if (!subHeader && this.isHeader2Line(paragraphText)) {
        subHeader = paragraph.outerHTML;
      } else {
        bodyContent = bodyContent ? bodyContent + paragraph.outerHTML : paragraph.outerHTML;
      }
    }

    return {
      header,
      subHeader,
      body: bodyContent,
      footer: footerContent,
    };
  }

  isHeader2Line(text: string): boolean {
    const knownHeaderPhrases = [
      /has been submitted/i,
      /has been rejected/i,
      /has been approved/i,
      /document submitted for approval/i,
      /a clinical plan management document has been submitted for your approval\./i,
      /an exception has been reported\./i,
    ];
    return knownHeaderPhrases.some((phrase) => phrase.test(text));
  }

  isFooterLine(text: string): boolean {
    const footerPhrases = [/please click here to review and approve\./i, /click here to view/i];
    return footerPhrases.some((phrase) => phrase.test(text));
  }

  handleDeleteClick() {
    this.deleteEvent.emit(this.notification.id);
  }

  private onViewDocumentClick() {
    this.adobeEvents.headerNavNotificationSubLink('view_document');
  }

  handleNotificationClick(event: MouseEvent) {
    if (!(event.target instanceof HTMLElement)) return;
    if (event.target.nodeName === 'A') {
      this.onViewDocumentClick();
    }
  }

  getNotificationRedirect(notificationDetail: string): string {
    let path = '';
    const arr = notificationDetail.split('href=');
    if (arr[1]) {
      path = arr[1].split('>')[0] || '';
    }
    // return empty string for invalid paths
    if (!path.includes('document')) {
      path = '';
    }
    return path;
  }
}
