import {Environment} from "./environment.model";

export const dashboardNavItem = {
  label: 'Dashboard',
  route: 'dashboard'
}

export const companyNavItem = (env: Environment) => {
  return {
    label: 'Client Info',
    children: [
      {
        label: 'Client Profile',
        route: 'clientinfo'
      },
      {
        label: 'Contacts',
        route: 'contacts',
        // children: [{
        //   label: 'Client Contacts',
        //   route: 'clientcontacts'
        // }, {
        //   label: 'CVS/Caremark Contacts',
        //   route: 'cvscontacts'
        // }, {
        //   label: 'Vendor Contacts',
        //   route: 'vendorcontacts'
        // }, {
        //   label: 'Consultant Contacts',
        //   route: 'consultantcontacts'
        // },
        // {
        //   label: 'Contacts MVP',
        //   route: 'contacts'
        // }]
      },
      // {
      //   label: 'Override Elections',
      //   route: '',
      //   children: [{
      //     label: 'Overrides',
      //     route: 'overrides'
      //   }]
      // }
      ...env.clientHierarchyFlag ? [{
        label: 'Client Hierarchy',
        route: 'clienthierarchy'
      }] : [],
    ],
    moreOptions: true
  }
}

export const clinicalNavItem = {
  label: 'Clinical Plans',
  children: [{
    label: 'Clinical Plan Management',
    route: 'clinical',
  }, {
    label: 'Formulary & Drug Search',
    route: 'formularysearch',
  }],
  moreOptions: true
}


// TODO: planhistory route?
export const benefitsAndPlansNavItem = {
  label: 'Benefit Plans',
  route: 'plandashboard',
  moreOptions: false
}

export const eligibilityNavItem = {
  label: 'Member Eligibility',
  route: 'eligibility',
  moreOptions: false
}

export const reportingNavItem = {
  label: 'Reporting',
  route: 'reporting'
}

export const AccessTypeAndNavigationAccess = (env: Environment) => {
  return {
    "CVS Health Colleague": [dashboardNavItem, companyNavItem(env), clinicalNavItem, benefitsAndPlansNavItem, eligibilityNavItem, reportingNavItem],
    "Client User": [dashboardNavItem, companyNavItem(env), clinicalNavItem, benefitsAndPlansNavItem, eligibilityNavItem, reportingNavItem],
    "Consultant User": [dashboardNavItem, companyNavItem(env), clinicalNavItem, benefitsAndPlansNavItem, eligibilityNavItem, reportingNavItem],
  }
}
