<ps-modal #clientSelectionModal size="lg" [heading]="(isChange ? 'Change Client' : 'Client Selection')"
  class="client-selection-modal" [hasCloseButton]="!!isChange">
  <h3 class="sub-heading">Welcome to myPBM! Select a client to continue</h3>
  <div class="modal-body">
    <ps-select>
      <select #clientSelect [(ngModel)]="selectedClientId" slot="select">
        <option value="">Select a Client</option>
        <option *ngFor="let client of allClients" [value]="client.clientUniqueId" [selected]="selectedClient?.clientUniqueId === client.clientUniqueId">
          {{client.clientName}}</option>
      </select>
    </ps-select>
  </div>
  <ps-button-group class="modal-buttons" slot="footer">
    <ps-button [disabled]="!selectedClientId" (click)="onClientSelect(clientSelect.value);goToDashboard()">Open
      Dashboard</ps-button>
    <ps-button *ngIf="isChange" (click)="onCancel()" variant="outlined">
      Cancel
    </ps-button>
  </ps-button-group>
</ps-modal>
