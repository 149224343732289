import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ModalService } from '../services/modal/modal.service';
import { ClientService } from '../services/client/client.service';
import { BasicUserInfo, ClientModel } from '@pbm-shared/pbm-shared-models';
import { AuthorizationService } from 'angular-component-library';
import { Router } from '@angular/router';
import { AdobeEventsService } from '../shared/adobe-events.service';

@Component({
  selector: 'app-client-selection-modal',
  templateUrl: 'client-selection-modal.component.html',
  styleUrls: ['client-selection-modal.component.scss'],
})
export class ClientSelectionModalComponent implements AfterViewInit {
  @Input() isChange: boolean;
  @ViewChild('clientSelectionModal') public cmkModal!: ElementRef;

  dialogInsertedObserver!: MutationObserver;
  allClients: ClientModel[] = [];
  selectedClient: ClientModel = null;
  selectedClientId: string = '';
  initialSelect: boolean = true;

  constructor(
    public modalService: ModalService,
    private clientService: ClientService,
    private authorizationService: AuthorizationService,
    private router: Router,
    private adobeEvents: AdobeEventsService
  ) {
    this.clientService.selectedClient$.subscribe((value) => {
      this.selectedClient = value;
    });
  }

  ngAfterViewInit() {
    this.authorizationService.getAuthorizedUser().subscribe((user) => {
      this.checkClientSelectionToShow(user.basicUserInfo);
    });
  }

  public checkClientSelectionToShow(userInfo: BasicUserInfo) {
    this.clientService.allClient$.subscribe((clients) => {
      const allClients = clients?.data || [];
      this.allClients = allClients.filter((client) => client.clientName);
    });
  }

  public onClientSelect(clientUniqueId: string) {
    const selectedClient = this.allClients.find(
      (client) => client.clientUniqueId === clientUniqueId
    );
    this.clientService.setClient(selectedClient);
  }

  public goToDashboard() {
    if (this.initialSelect) {
      this.adobeEvents.clientSelectionGoToDashboardLink();
    } else {
      this.adobeEvents.changeClientGoToDashboardLink();
    }
    this.modalService?.closeModalDialog();
    this.routeToDashboard();
  }

  public openModal(): void {
    if (this.selectedClient) {
      this.initialSelect = false;
      this.adobeEvents.changeClientOnLoadLink();
    } else {
      this.adobeEvents.clientSelectionPageLoad();
    }
    if (this.cmkModal.nativeElement?.querySelector('.ps-modal')) {
      this.openModalWithSelector(this.cmkModal.nativeElement);
      return;
    }
    this.dialogInsertedObserver = new MutationObserver((): void => {
      this.openModalWithSelector(this.cmkModal.nativeElement);
    });

    this.dialogInsertedObserver.observe(this.cmkModal.nativeElement, {
      attributes: true,
      childList: true,
      characterData: true,
    });
  }

  public openModalWithSelector(element) {
    this.modalService.setModalDialog(
      element.querySelector('.ps-modal') as HTMLDialogElement
    );
  }

  public routeToDashboard() {
    const { url } = this.router;
    if (url === '/' || url.includes('/dashboard')) {
      //this.router.navigateByUrl('/stub', { skipLocationChange: true }).then(() => {
      this.router.navigate([this.selectedClient.clientUniqueId, 'dashboard']);
      //});
      return;
    }
    this.router.navigate([this.selectedClient.clientUniqueId, 'dashboard']);
  }

  public onCancel() {
    this.adobeEvents.changeClientCancelLink();
    this.modalService.closeModalDialog()
  }
}
